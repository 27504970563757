import { ReceiptModel, ReceiptBaseModel } from "../models/Receipt.model";

// FIREBASE
import * as firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage'; // allow read, write: if request.auth != null;
import 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyDHGqNDbYwpX8dgeI7_MdEUj9dwWqfZ5wA',
  authDomain: 'easy-lunch-planning.firebaseapp.com',
  databaseURL: 'https://easy-lunch-planning.firebaseio.com',
  projectId: 'easy-lunch-planning',
  storageBucket: 'easy-lunch-planning.appspot.com',
  messagingSenderId: '110426642416',
  appId: '1:110426642416:web:c439d7f90f5ec88ea7af35'
};
firebase.initializeApp(firebaseConfig);
const database = firebase.database();

export class ReceiptService {
  public static async deleteReceipt(receipt: ReceiptModel): Promise<boolean> {
    //Get the current userID
    const userId = firebase.auth().currentUser ? firebase.auth().currentUser?.uid : 'public-user';
    const receipts = database.ref('Receipts/' + userId);
    try {
      await receipts
        .child(receipt.id)
        .remove();
      
      return true;
    } catch (error) {
      return false;
    }
  }

  public static async getAllReceiptsListener(callback: Function): Promise<any> {
    const baseProvider = new firebase.auth.GoogleAuthProvider();
    let userId = 'public-user';
    try {
      const firebaseResponse = await firebase.auth().signInWithPopup(baseProvider);
      if (firebaseResponse.user) {
        console.log('user logged in successfully:', firebaseResponse.user.displayName);
        userId = firebaseResponse.user.uid;
      } else {
        console.log('user used auth, but no user obj in response', firebaseResponse);
      }
    } catch (err) {
      console.log('user aborted google auth', err);
    }
    // wait here for login... obviously not the best place here, but yeah. for now.
    return database.ref('Receipts/' + userId).on('value', (snapshot) => callback(snapshot));
  }

  public static mapReceiptSnapshotToArray(snapshot: firebase.database.DataSnapshot): Array<ReceiptModel> {
    const items = snapshot.val();
    
    if (!items) {
      return [];
    }
  
    // mapping happens here to create Receipt Array
    // firebase does not store an array but an object with keys in it
    const listItems: Array<ReceiptModel> = Object.keys(items).map((itemId) => {
      const receipt = items[itemId];
      return {
        id: itemId,
        ...receipt,
      }
    });
  
    return listItems;
  }

  public static async getAllReceiptsOnce(): Promise<ReceiptModel[]> {
    //Get the current userID
    const userId = firebase.auth().currentUser ? firebase.auth().currentUser?.uid : 'public-user';
    const receipts = database.ref('Receipts/' + userId);
    const itemsSnapshot = await receipts.once('value');
    return this.mapReceiptSnapshotToArray(itemsSnapshot);
  }

  public static async createNewReceipt(receipt: ReceiptBaseModel): Promise<ReceiptModel> {
    try {
      //Get the current userID
      const userId = firebase.auth().currentUser ? firebase.auth().currentUser?.uid : 'public-user';
      const receipts = database.ref('Receipts/' + userId);
      const newReceiptRef = receipts.push();
      await newReceiptRef.set({
        ...receipt,
      });
      const itemSnapshot = await newReceiptRef.once('value');

      return {
        id: itemSnapshot.key,
        ...itemSnapshot.val(),
      };
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public static async uploadImageAndGetDownloadUrl (image: File): Promise<string> {
    try {
      const storageRef = firebase.storage().ref();
      const mountainImagesRef = storageRef.child(`fileuploads/${image.name}`);
      const uploadedImageSnapshot = await mountainImagesRef.put(image);
      const fileUrl: string = await uploadedImageSnapshot.ref.getDownloadURL();
      return fileUrl;
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
