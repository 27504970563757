import React, { useEffect }  from 'react';
import './ReceiptList.scss';

// icons
import { IoMdTrash, IoIosPizza } from "react-icons/io";

// Receipt stuff
import { ReceiptService } from '../services/Receipt.service';
import { ReceiptModel } from '../models/Receipt.model';


const listGenerator = (receipts: Array<ReceiptModel>) => {
  if (receipts.length === 0) {
    return <div>o.O Keine Rezepte in der Datenbank...</div>
  }

  const listItems = receipts.map((receipt) => {
    let imageElement = <IoIosPizza className="placeholder-image receipt-image" size="100" />;
    if (receipt.imageUrl) {
      const elementInlineStyles = {
        backgroundImage: `url(${receipt.imageUrl})`
      };

      imageElement = <div className="receipt-image" style={elementInlineStyles} />;
    }
    return (
      <div 
        className="list-item"
        key={receipt.id}
      >
        <div className="remove-receipt" onClick={() =>
          window.confirm("Ist das Rezept so kacke? Also löschen? Bisch dir dabei sicher?") &&
          ReceiptService.deleteReceipt(receipt)
        }>
          <IoMdTrash className="remove-receipt__icon" size="20" />
        </div>
        {imageElement}
        <h3>{receipt.name} <br/> <span className="small">({receipt.type})</span></h3>
      </div>
    );
  });
  return <div className="receipt-list-wrapper">{listItems}</div>;
}

const ReceiptList: React.FC = () => {
  const [
    allReceiptsList,
    setAllReceiptsList,
  ] = React.useState<Array<ReceiptModel> | null>(null); // use null here for initial loading state indicator

  // this usage of useEffect works as "componentDidMount"
  // https://medium.com/@felippenardi/how-to-do-componentdidmount-with-react-hooks-553ba39d1571
  useEffect(() => {
    ReceiptService.getAllReceiptsListener((snapshot: firebase.database.DataSnapshot) => {
      setAllReceiptsList([
        ...ReceiptService.mapReceiptSnapshotToArray(snapshot),
      ]);
    });
  }, [])

  let content = <div>unknown error here...</div>;
  if (allReceiptsList === null) {
    content = <div>Rezepte werden geladen...</div>
  } else {
    content = listGenerator(allReceiptsList);
  }

  return content;
}

export default ReceiptList;
