import React from 'react';
import './App.scss';

// notifications
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

// custom components
import ReceiptList from "./components/ReceiptList";
import CreateNewReceipt from "./components/CreateNewReceipt";
import { IoMdAddCircle } from 'react-icons/io';

const App: React.FC = () => {
  const [newReceiptOpen, setNewReceiptOpen] = React.useState<boolean>(false);

  function uplodaSuccessful() {
    setNewReceiptOpen(false);
    window.scrollTo({left: 0, top: document.body.scrollHeight, behavior: 'smooth'});
  }

  return (
    <div className="App">
      <ReactNotification />
      <header>
        <div className="logo-wrapper">
          <img src="./nicos-logo.png" alt="logo"/>
        </div>
      </header>
      <div className="all-content">
        <h2>Alle Rezepte</h2>
        <ReceiptList />
        <IoMdAddCircle
          className="clickable add-new-receipt-button"
          size="50"
          onClick={() => setNewReceiptOpen(!newReceiptOpen)}
        />
        {newReceiptOpen && (
          <div className="new-receipt-modal">
            <CreateNewReceipt onSuccessfulUpload={uplodaSuccessful} />
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
