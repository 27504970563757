import * as React from "react";
import "./CreateNewReceipt.scss";

import { ReceiptType } from "../models/Receipt.model";
import { ReceiptService } from "../services/Receipt.service";

// notification
import { store as NotificationService } from "react-notifications-component";

// icons
import { IoMdCheckmark } from "react-icons/io";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
// import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageCrop from "filepond-plugin-image-crop";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
registerPlugin(
  // FilePondPluginImageExifOrientation, // this does actually make problems and does not solve them...?
  FilePondPluginImageResize,
  FilePondPluginImageCrop,
  FilePondPluginImageTransform,
  FilePondPluginImagePreview
);

const notificationBaseConfig = {
  insert: "top",
  container: "top-right",
  animationIn: ["animated", "fadeIn"],
  animationOut: ["animated", "fadeOut"],
  dismiss: {
    duration: 5000,
    onScreen: false,
  },
};

type CreateNewReceiptProps = {
  onSuccessfulUpload: Function;
}

const CreateNewReceipt: React.FC<CreateNewReceiptProps> = ({ onSuccessfulUpload }) => {
  const [newReceiptName, setNewReceiptName] = React.useState("");
  const [pondRef, setPondRef] = React.useState<any>(null);
  const [veggieOrVegan, setCeggieOrVegan] = React.useState(ReceiptType.VEGAN);
  const [currentImage, setCurrentImage] = React.useState<any>(null);
  const [disabledAddButton, setDisabledAddButton] = React.useState<boolean>(false);

  const createNewReceipt = async () => {
    if (newReceiptName === "") {
      NotificationService.addNotification({
        ...notificationBaseConfig,
        title: "Musst schon benennen!",
        message: "Ohne Name wird das nix",
        type: "info",
      });
      return;
    }

    try {
      // lets see if there is an image and when yes upload it to get url
      let imageUrl = "";
      setDisabledAddButton(true);
      if (currentImage !== null) {
        const downloadUrl = await ReceiptService.uploadImageAndGetDownloadUrl(currentImage);
        if (downloadUrl) {
          imageUrl = downloadUrl;
        }
      }
      // try to safe this
      const createdReceipt = await ReceiptService.createNewReceipt({
        name: newReceiptName,
        type: veggieOrVegan,
        imageUrl: imageUrl,
      });
      // when successfull, reset input
      setNewReceiptName("");
      pondRef.removeFiles();
      // inform user
      NotificationService.addNotification({
        ...notificationBaseConfig,
        title: createdReceipt.name,
        message: "Rezept wurde hinzugefügt!",
        type: "success",
      });
      // notify parent that upload was successful
      onSuccessfulUpload();
    } catch (error) {
      NotificationService.addNotification({
        ...notificationBaseConfig,
        title: "Hupsi Daisy",
        message: "Da ging etwas beim speichern schief.",
        type: "warning",
      });
    } finally {
      setDisabledAddButton(false);
    }
  };

  return (
    <div className="create-receipt-wrapper">
      <h2>Neues Rezept</h2>
      <input
        type="text"
        value={newReceiptName}
        onChange={(e) => setNewReceiptName(e.target.value)}
        placeholder="Name des Rezepts"
        className="css-input"
      />
      <div className="control-group type-selector">
        <label className="control control-checkbox">
          Vegan
          <input
            type="radio"
            name="veggieOrVegan"
            value={ReceiptType.VEGAN}
            checked={veggieOrVegan === ReceiptType.VEGAN}
            onChange={(e) => setCeggieOrVegan(ReceiptType.VEGAN)}
          />
          <div className="control_indicator"></div>
        </label>
        <label className="control control-checkbox">
          Veggie
          <input
            type="radio"
            name="veggieOrVegan"
            value={ReceiptType.VEGGIE}
            checked={veggieOrVegan === ReceiptType.VEGGIE}
            onChange={(e) => setCeggieOrVegan(ReceiptType.VEGGIE)}
          />
          <div className="control_indicator"></div>
        </label>
      </div>
      <FilePond
        className="filepond-element"
        ref={(ref) => setPondRef(ref)}
        allowMultiple={false}
        // @ts-ignore // needed because no type definitions for this plugin exist...
        imageCropAspectRatio="1:1"
        imageResizeTargetWidth={1080}
        imageTransformOutputMimeType="image/jpeg"
        imageTransformVariantsIncludeOriginal={true}
        onpreparefile={(file, output) => {
          // dont ask... 
          // "imageTransformVariantsIncludeOriginal" needs to be true
          // then i can access the converted image and set it with "setCurrentImage"
          // then the second object in this 
          setCurrentImage(output[1].file);
        }}
      />
      <button
        className={'clickable add-item-button ' + (newReceiptName === '' ? 'add-item-button--disabled' : '')}
        onClick={createNewReceipt}
      >
        <IoMdCheckmark
          className="button-icon"
          size="25"
        />
        {disabledAddButton ? '...' : 'Hinzufügen'}
      </button>
    </div>
  );
};

export default CreateNewReceipt;
