export interface ReceiptBaseModel {
  name: string;
  type: ReceiptType;
  imageUrl: string;
}

export interface ReceiptModel extends ReceiptBaseModel {
  id: string;
}

export enum ReceiptType {
  VEGAN = 'vegan',
  VEGGIE = 'veggie',
}
